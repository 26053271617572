//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #a6d344;
$primary-color-l: #eaebc6;
$gray: #656565;

//Fonts
$primary-font: 'Suisse Int\'l', sans-serif;
$secondary-font: 'Suisse Int\'l Mono', sans-serif;
