@font-face {
    font-family: 'Suisse Int\'l Mono';
    src: url('../font/SuisseIntlMono-Regular.woff2') format('woff2'),
        url('../font/SuisseIntlMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../font/SuisseIntl-Medium.woff2') format('woff2'),
        url('../font/SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l Mono';
    src: url('../font/SuisseIntlMono-Bold.woff2') format('woff2'),
        url('../font/SuisseIntlMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../font/SuisseIntl-Bold.woff2') format('woff2'),
        url('../font/SuisseIntl-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../font/SuisseIntl-Regular.woff2') format('woff2'),
        url('../font/SuisseIntl-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../font/SuisseIntl-Book.woff2') format('woff2'),
        url('../font/SuisseIntl-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

