@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
    @media screen and (max-width: 1540px){
        font-size: 14px;
    }
    @media screen and (max-width: 1300px){
        font-size: 12px;
    }
    @media screen and (max-width: 1140px){
        font-size: 10px;
    }
    @media screen and (max-width: 980px){
        font-size: 16px;
    }
    
    @media screen and (max-width: 399px){
        font-size: 13px;
    }
}

@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    10%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes fadeOut{
    0%{
        opacity: 1;
    }
    90%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

body {
    background-color: $white;
    color: $black;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &::after{
        content: '';
        position: fixed;
        z-index: 10;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 1;
        animation: fadeOut 2s forwards ease-in-out;
    }
    &.fadeOut{
        &::after{
            opacity: 0;
            animation: fadeIn 1s forwards ease-in-out;
        }
    }
    #main{
        flex: 1;
    }
}

a{
    color: inherit;
    transition: .3s all;
    transform-origin: bottom center;
    display: inline-block;
    @include hover{
            transform: scale(1.1);
    }
}

.container{
    max-width: rem(1800);
}

body{
    position: relative;
    .cart-icon{
        display: none;
        position: absolute;
        top: rem(40);
        right: rem(20);
        @media screen and (max-width: 980px){
            width: rem(36)
        }
        
    }

    .contacticon{
        position: absolute;
        bottom: rem(40);
        right: rem(20);
        @media screen and (max-width: 980px){
            width: rem(65);
        }
        img{
            display: block;
        }
    }
}

.home{
    .cart-icon{
        position: absolute;
        top: rem(40);
        right: rem(20);
    }
    
    .stems{
        position: absolute;
        bottom: rem(32);
        right: rem(120);
        img{
            display: block;
        }
        @media screen and (max-width: 980px){
            display: none;
        }
    }
    .contacticon{
        position: absolute;
        bottom: rem(72);
        right: rem(20);
        img{
            display: block;
        }
    }
}

.music, .events, .shop, .contact{
    #header{
        background-image: url('../img/header-bar.png');
        background-size: 100% 70%;
        background-repeat: no-repeat;
        background-position: center;
        
        @media screen and (max-width: 980px){
            background-image: url('../img/header-bar-mobile.png');
            background-size: 100% 100%;
            padding-bottom: rem(20);
        }
        .container{
            display: flex;
            align-items: center;
            @media screen and (max-width: 980px){
                display: block;
                text-align: center;
            }
        }
        h1{
            margin: 0 0 rem(-50) rem(-50);
            width: rem(466);
            @media screen and (max-width: 980px){
                width: 100%;
                max-width: rem(330);
                margin: 0 auto;
            }
        }
        .nav{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            a{
                display: inline-block;
                @media screen and (max-width: 980px){   
                    margin: 0 rem(10);                 
                    &:first-child svg{
                        transform: rotate(-45deg) translateY(rem(-20));
                        width: rem(110);
                    }
                    &:nth-child(2) svg{
                        width: rem(75);
                    }
                    &:last-child svg{
                        width: rem(110);
                    }
                }
                
                @media screen and (min-width: 981px){
                    margin: 0 rem(30);
                    min-width: rem(100); 
                    &:first-child svg{
                        transform: rotate(-25deg) translateY(rem(20));
                        width: rem(130);
                    }
                    &:nth-child(2) svg{
                        width: rem(100);
                    }
                    &:last-child svg{
                        width: rem(130);
                    }
                }
                svg{
                    max-width: 100%;
                    height: auto;
                    path{
                        fill: $primary-color-l;
                        transition: .3s all;
                    }
                }
                @include hover{
                    svg path{
                        fill: $primary-color;
                    }
                }
                &.current{
                    svg path{
                        fill: $primary-color;
                    }
                }
            }
        }
    }
}

.events{
    background-image: url('../img/genbg.png');
    background-size: cover;
    background-position: top center;

    #main{
        padding: rem(80) 0 rem(50);
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: stretch;
        .container{
            max-width: rem(900);
            @media screen and (max-width: 980px){
                max-width: rem(600);
            }
        }
        #events{
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media screen and (max-width: 980px){
                padding-left: rem(40);
            }
            .noevents{
                text-align: center;
                flex-basis: 100%;
            }
            .event{
                flex-basis: 46%;
                @media screen and (max-width: 980px){
                    flex-basis: 100%;
                }
                margin: rem(5) 0;
                position: relative;
                &::before{
                    content:'';
                    width: rem(22);
                    height: rem(18);
                    background-image: url('../img/arrow.png');
                    background-size: cover;
                    position: absolute;
                    left: rem(-30);
                    top: 50%;
                    transform: translateY(-50%);

                }
            }
            a{
                display: flex;
                text-align: left;
                align-items: center;
                text-decoration: none;
                transform-origin: center;
            }
            .event-date{
                background-image: url('../img/ovalthing.png');
                background-size: 100% 100%;
                padding: rem(10) rem(10);
                font-weight: bold;
                font-size: rem(20);
                text-align: center;
                width: rem(130);
                flex-shrink: 0;
                color: $white;
                @media screen and (max-width: 980px){
                    font-size: rem(13);
                    width: rem(80);
                }
            }
            .event-location{
                margin-left: rem(15);
                font-family: $secondary-font;
                font-size: rem(18);
                color: $primary-color;
                @media screen and (max-width: 980px){
                    font-size: rem(14);
                }
            }

            .event.soldout{
                &::before{
                    content:'';
                    width: rem(80);
                    height: rem(60);
                    background-image: url('../img/so.png');
                    left: 50%;
                    transform: translate(-50%,-50%);
                    pointer-events: none;z-index: 10;
                }

                &::after{
                    content:'';
                    width: 100%;
                    height: 100%;
                    background-image: url('../img/X.png');
                    background-size: 100% 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    z-index: 10;

                }
                .event-date{
                    background-image: url('../img/ovalthing-so.png');
                }
            }
        }
    }
}

.shop{
    background-image: url('../img/genbg.png');
    background-size: cover;
    background-position: top center;

    #main{
        padding: rem(50) 0;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: stretch;
    }
}

.contact{
    background-image: url('../img/genbg.png');
    background-size: cover;
    background-position: top center;

    #header{
        background: none;

    }

    #main{
        padding: 0 0 rem(50);
        position: relative;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            font-size: rem(14);
            span{
                display: block;
                text-transform: uppercase;
            }
        }
    }
}

.music{
    background-image: url('../img/musicbg.png');
    background-size: cover;
    background-position: top center;
    @media screen and (max-width: 980px){
        background-position: center right;
    }

    #main{
        position: relative;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        @media screen and (max-width: 980px){
            padding: rem(50) 0 rem(80);
        }
        .container{
            display: flex;
            justify-content: space-between;
            
            @media screen and (max-width: 980px){
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: rem(400);
            }

        }
        .links{
            position: relative;
            align-self: flex-end;
            z-index:2;
            display: flex;
            align-items: flex-end;
            a{
                display: inline-block;
                &:first-child{
                    position: relative;
                    right: rem(-40);
                    top: rem(-40);
                    width: rem(467);
                    @media screen and (max-width: 980px){
                        width: rem(190);
                        top: rem(-20);
                        right: rem(-20);
                    }
                }
                &:nth-child(2){
                    width: rem(405);
                    
                    @media screen and (max-width: 980px){
                        width: rem(174);
                    }
                }
                img{
                    display: block;
                }
            }
        }
        .arrow{
            @media screen and (max-width: 980px){
                display: none;
            }
            span, img{
                display: inline-block;
                vertical-align: middle;
            }
            span{
                font-weight: bold;
                text-transform: uppercase;
                font-size: rem(20);
            }
            &:nth-child(3){
                position: absolute;
                top: 0;
                left: 50%;
            }
            &:nth-child(4){
                position: absolute;
                bottom: rem(10);
                right: 50%;
            }
        }

        .video{
            margin-top: rem(-70);
            width: 100%;
            pointer-events: none;
        }
        .video-wrapper{
            width:rem(600);
            max-width: 100%;
            position: relative;
            padding: rem(125) rem(50) 0 rem(30);
            @media screen and (max-width: 980px){
                padding: 20% 10% 20% 4%;    
            }
            .media-responsive{
                pointer-events: all;
            }
            
            .frame{
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
            }
        }
    }
}

.home #main{
    position: relative;
    text-align: center;
    background-image: url('../img/homebg.png');
    background-size: cover;
    padding: rem(50) 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    h1{
        margin: 0;
    }
    @media screen and (max-width: 980px){
        padding-top: rem(100);
        h1{
            img{
                max-width: 120%;
                margin-left: -10%;
            }
            transform: rotate(-10deg);
        }
    }
    .nav{
        margin-top: rem(30);
        display: flex;
        justify-content: center;
        align-items: center;
        
        @media screen and (max-width: 980px){
            flex-direction: column;
            a:first-child svg{
                transform: rotate(-40deg);
            }
            a:nth-child(2) svg{
                width: rem(120);
            }
            a:nth-child(3){
                margin-top: rem(20);
            }
        }
        a{
            margin: 0 rem(30);
            display: inline-block;
            min-width: rem(160);
            svg{
                width: 100%;
                height: auto;
            }
        }
    }
}

#footer{
    background-color: $black;
    color: $white;
}

.copyright{
    font-size: rem(10);
    text-transform: uppercase;
    text-align: center;
}